import React from "react";
import "../components/Services.css";

import ElderlyCare from "../assets/Elderly Care.jpeg";
import PregnancyCare from "../assets/Pregnancy Care.jpeg";
import PatientCare from "../assets/Patient Care.jpeg";
import BabyCare from "../assets/Baby Care.jpeg";
import HouseMaidServices from "../assets/House Maid Services.jpeg";

const Services = () => {
  return (
    <>
      <section id="services">
        <h3>Our Services</h3>
        <div className="cards">
          <div className="r-1">
            <div id="EC">
              <img src={ElderlyCare} alt="Elderly Care" />
              <div className="imgcover">
                <p>Elderly Care</p>
                <div className="inside-content">
                  <p>
                    Embrace the golden years with our dedicated elderly care
                    services. Our trained and empathetic caregivers ensure that
                    your senior family members receive the support they need
                    while maintaining their independence and dignity. <br />
                    <br />
                    +91 9072260833, +91 9188630833
                  </p>
                  <a id="call-btn" href="tel:9188630833">
                    Call Now
                  </a>
                </div>
              </div>
            </div>
            <div id="PC">
              <img src={PatientCare} alt="Patient Care" />
              <div className="imgcover">
                <p>Patient Care</p>
                <div className="inside-content">
                  <p>
                    For those recovering from illness or surgery, our patient
                    care services ensure a smooth and comfortable transition to
                    health. Our compassionate caregivers assist with medication
                    management, mobility support, and more, fostering a
                    nurturing environment for recovery. <br />
                    <br />
                    +91 9072260833, +91 9188630833
                  </p>
                  <a id="call-btn" href="tel:9188630833">
                    Call Now
                  </a>
                </div>
              </div>
            </div>
            <div id="HM">
              <img src={HouseMaidServices} alt="House Maid Services" />
              <div className="imgcover">
                <p>House Maid Services</p>
                <div className="inside-content">
                  <p>
                    Maintain a clean and organized home effortlessly with our
                    reliable house maid services. Our trained and trustworthy
                    staff will take care of the chores, allowing you to focus on
                    what matters most quality time with your loved ones. <br />
                    <br />
                    +91 9072260833, +91 9188630833
                  </p>
                  <a id="call-btn" href="tel:9188630833">
                    Call Now
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="r-2">
            <div id="PRC">
              <img src={PregnancyCare} alt="Pregnancy Care" />
              <div className="r-2-imgcover">
                <p>Pregnancy Care</p>
                <div className="inside-content">
                  <p>
                    Celebrate the journey to parenthood with confidence. Our
                    skilled and caring professionals provide comprehensive
                    pregnancy care, offering support and guidance to expectant
                    mothers during this special time.
                    <br />
                    <br />
                    +91 9072260833, +91 9188630833
                  </p>
                  <a id="call-btn" href="tel:9188630833">
                    Call Now
                  </a>
                </div>
              </div>
            </div>
            <div id="BC">
              <img src={BabyCare} alt="Baby Care" />
              <div className="r-2-imgcover">
                <p>Baby Care</p>
                <div className="inside-content">
                  <p>
                    Welcoming a new addition to the family is a joyous occasion.
                    Our baby care services provide parents with peace of mind,
                    offering expert assistance with feeding, diapering, and
                    nurturing your little one. <br />
                    <br />
                    +91 9072260833, +91 9188630833
                  </p>
                  <a id="call-btn" href="tel:9188630833">
                    Call Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
