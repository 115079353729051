import React, { useRef } from "react";
import logoIntouch from "/Users/mishabka/Developer/WORKING/React Js/Projects/InTouch Home Nursing & Life Car/intouch/src/assets/Intouch-logo.svg";
import "../components/Header.css";
import { FaBars, FaTimes } from "react-icons/fa";
const Header = () => {
  const navRef = useRef();

  const showNavBar = () => {
    navRef.current.classList.toggle("res_nav");
  };

  return (
    <>
      <section id="header">
        <img src={logoIntouch} alt="LOGO-INTOUCH" />
        <nav id="nav-link" ref={navRef}>
          <div className="nav-inside">
            <a href="#home">Home</a>
            <a href="#home">Services</a>

            <a id="call-btn" href="tel:9188630833">
              Call Now
            </a>
          </div>
          <button
            id="mobile-bar"
            className="nav-btn nav-close-btn"
            onClick={showNavBar}
          >
            <FaTimes />
          </button>
        </nav>
        <button className="nav-btn" onClick={showNavBar}>
          <FaBars />
        </button>
      </section>
    </>
  );
};

export default Header;
