import React from "react";
import "../components/Home.css";
import landing_image from "../assets/img-1.jpeg";
const Home = () => {
  return (
    <>
      <section id="Landing">
        <div className="wrapper">
          <div className="l-s-2-mobile">
            <div className="c2"></div>
            <img src={landing_image} alt="landing_image" />
            <div className="cover">
              <h3> Your Trusted Partner in Compassionate Care</h3>
            </div>
          </div>
          <div className="l-s-1">
            <h1>
              Welcome to <span>InTouch Home Nursing & Life Care</span> Your
              Trusted Partner in Compassionate Care{" "}
            </h1>

            <p>
              At <span>InTouch</span>, we understand that family is everything,
              and ensuring the well-being of your loved ones is of paramount
              importance. That's why we're here to provide compassionate and
              personalized care services tailored to meet the unique needs of
              your family.
            </p>
            <p>+91 9072260833, +91 9188630833</p>
            <div className="l-btn">
              <a id="call-btn" href="tel:9188630833">
                Call Now
              </a>
              <a id="booknow" href="tel:9072260833">
                Book Now
              </a>
            </div>
          </div>
          <div className="l-s-2">
            <div className="c2"></div>
            <img src={landing_image} alt="landing_image" />
            <div className="cover">
              <h3> Your Trusted Partner in Compassionate Care</h3>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
