import React from "react";
import logoIntouch from "/Users/mishabka/Developer/WORKING/React Js/Projects/InTouch Home Nursing & Life Car/intouch/src/assets/Intouch-logo.svg";
import "../components/Footer.css";
const Footer = () => {
  return (
    <>
      <section id="footer">
        <div className="footer">
          <img src={logoIntouch} alt="LOGO-INTOUCH" />
          <div className="contactus">
            <h3>Contact Us Today</h3>
            <p>
              Make the choice for exceptional care that your family deserves.
              Contact <span>InTouch Home Nursing & Life Care</span> today to
              discuss your specific needs and let us tailor a care plan that
              brings comfort, joy, and peace of mind to your home.
            </p>
          </div>
          <div className="f-wrapper">
            <a href="#home">Home</a>
            <br />
            <br />
            <a href="#home">Services</a>
            <br />
            <p>+91 9072260833, +91 9188630833</p>
            <br />
            <a id="call-btn" href="tel:9188630833">
              Call Now
            </a>

            <a id="call-btn" href="tel:9072260833">
              Book Now
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
