import React from "react";
import "../components/AboutUs.css";
import why from "../assets/why.svg";
const AboutUs = () => {
  return (
    <>
      <section id="aboutus">
        <img src={why} alt="Why Choose Us?" />
        <div className="a-wrapper">
          <div className="a-txt">
            <h1>About Us</h1>
            <li>
              <span>Compassionate Caregivers:</span> Our team is comprised of
              compassionate professionals dedicated to providing the highest
              standard of care for your family members.
            </li>
            <li>
              <span>Tailored Solutions:</span> We understand that every family
              is unique. Our services are personalized to meet the specific
              needs and preferences of your loved ones.
            </li>
            <li>
              <span>Reliability and Trust:</span> With a commitment to
              reliability and trust, we strive to build lasting relationships
              with our clients based on transparency and integrity.
            </li>
            <li>
              <span>Quality Assurance:</span> We uphold rigorous quality
              standards to ensure that our services consistently meet and exceed
              expectations.
            </li>
            <p>+91 9072260833, +91 9188630833</p>
            <div className="btn-a">
              <a id="call-btn" href="tel:9188630833">
                Call Now
              </a>

              <a id="call-btn" href="tel:9072260833">
                Book Now
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
